//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import appTheme from "../../../assets/appTheme";
import './DLToast.css'


class DLToastMessageView extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.systemError.isVisible) {
      if (!prevProps.systemError.reloadApp && this.props.systemError.reloadApp) {
        setTimeout(() => {
          document.location.reload();
        }, 7000);
      }
    }
  }


  reloadApp() {
    window.location.reload();
  }


  render() {
    const {closeToastAction, hideSystemErrorBlock, systemError, isXSView} = this.props;
    const {type, isVisible, message, timeout} = this.props.toastStatus;

    let messageUI = null;
    switch (type) {
      case 'success':
        messageUI = <Alert severity="success" className="success-alert" variant="filled">{message}</Alert>;
        break;
      case 'danger':
        messageUI = <Alert severity="error" className="error-alert" variant="filled">{message}</Alert>;
        break;
      case 'info':
        messageUI = <Alert severity="info" className="info-alert" variant="filled">{message}</Alert>;
        break;
      case 'warning':
        messageUI = <Alert severity="warning" className="warning-alert" variant="filled">{message}</Alert>;
        break;
      default:
        break;
    }

    return (

      <>
        <Snackbar
            anchorOrigin={{vertical: isXSView ? 'bottom' : 'top', horizontal: 'center'}}
          open={isVisible}
          onClose={closeToastAction}

          style={{marginBottom: isXSView && 50 }}
          autoHideDuration={timeout}>
          {messageUI}
        </Snackbar >

        <div className='dl-toast'>
          <Snackbar
            open={systemError.isVisible}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            bodyStyle={{maxWidth: '100vh'}}
            autoHideDuration={100}>
            <div
              className="dl-toast rounded-1 d-flex justify-content-center align-items-center"
              style={{
                minWidth:"350px !important",
                maxWidth:'350px !important',
                width:'auto',
                background: appTheme.appColor.danger,
                color: '#FFF',

                padding: '12px 10px'
              }}>
              <i className="fa-solid fa-triangle-exclamation mx-2" />
              <div className="mx-2">
                {navigator.onLine
                  ?
                  <span>Something went wrong. Please try again or contact us</span>
                  :
                  <span>No Internet Connection</span>
                }
              </div>
              <i className="fa-solid fa-rotate-right mx-2 cursor"
                 onClick={() => this.reloadApp()}
              />
              <i className="fa-solid fa-xmark mx-2 cursor"
                 onClick={hideSystemErrorBlock}
              />
            </div>
          </Snackbar>
        </div>

      </>
    );
  }

}

export default DLToastMessageView;
